<template>
  <div class="container min-height flex-column justify-content-spaceBetween align-items-center">
    <div class="width-100 flex-column justify-content-start align-items-center">
      <top :active="-1"></top>
      <div class="container-box copyrightRegistration flex-column justify-content-start align-items-center">
        <div class="width-100 flex-row justify-content-start align-items-center border-b">
          <p class="fs-super-big black fw-mid">银行入驻申请</p>
        </div>
        <div class="width-60 form-box">
          <el-form ref="form" :model="form" :rules="rules">
            <el-form-item label="银行名称" prop="name">
              <el-input v-model="form.name" placeholder="请输入银行名称"></el-input>
            </el-form-item>
            <el-form-item label="银行法人" prop="legalName">
              <el-input v-model="form.legalName" placeholder="请输入银行法人"></el-input>
            </el-form-item>
            <el-form-item label="联系人" prop="contactName">
              <el-input v-model="form.contactName" placeholder="请输入联系人"></el-input>
            </el-form-item>
            <el-form-item label="联系方式" prop="contactMobile">
              <el-input v-model="form.contactMobile" placeholder="请输入联系方式"></el-input>
            </el-form-item>
            <el-form-item label="服务内容" prop="blurb">
              <el-input v-model="form.blurb" type="textarea" :autosize="{ minRows: 10, maxRows: 15 }" placeholder="可以提供的服务详细说明"></el-input>
            </el-form-item>
            <el-form-item label="律所资料" prop="image">
              <div class="width-100 flex-row justify-content-start align-items-star">
                <el-upload
                  class="avatar-uploader"
                  :action="uploadImgUrl"
                  :headers="headers"
                  :show-file-list="false"
                  :on-success="handleImageSuccess"
                  :before-upload="beforeImageUpload"
                >
                  <el-image v-if="form.image" :src="imagePrefix + form.image" class="avatar" />
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
                <div class="margin-l">
                  <p class="darkgrey opacity-8 line-height2 margin-l">律所logo</p>
                  <p class="darkgrey opacity-8">（不超过2M）</p>
                </div>
              </div>
            </el-form-item>
            <el-form-item prop="file">
              <div class="width-100 flex-row justify-content-start align-items-star">
                <el-upload
                  class="avatar-uploader"
                  :action="uploadImgUrl"
                  :headers="headers"
                  :show-file-list="false"
                  :on-success="handleFileSuccess"
                  :before-upload="beforeImageUpload"
                >
                  <img v-if="form.file" :src="imagePrefix + form.file" class="avatar" />
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
                <div class="margin-l">
                  <p class="darkgrey opacity-8 line-height2 margin-l">其他资料</p>
                  <p class="darkgrey opacity-8">（可上传营业执照等资料）</p>
                </div>
              </div>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="onSubmit">提交</el-button>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
    1
    <bottom></bottom>
  </div>
</template>
<script>
import top from '../components/top';
import bottom from '../components/footer';
import { financeBankSave } from '@/api/TranFinanceBank';
export default {
  name: 'financialServiceSign',
  components: {
    top,
    bottom
  },
  data() {
    return {
      uploadImgUrl: process.env.VUE_APP_SERVE_PATH + '/common/upload', // 上传的图片服务器地址
      imagePrefix: process.env.VUE_APP_IMAGE_PREFIX,
      checked: false,
      dialogVisible: false,
      search: '',
      loading: null,
      uploadProfile: {
        fileSize: 2, // 文件最大尺寸
        fileType: ['jpg', 'png'] // 文件格式限制
      },
      headers: {
        Authorization: localStorage.getItem('bqsb_login')
      },
      form: {
        name: '', //名称
        legalName: '', //法人
        contactName: '', //联系人
        contactMobile: '', //联系方式
        serviceScope: '', //范围
        blurb: '', //内容
        image: null, //logo图片
        file: null //其他资料
      },
      rules: {
        name: [{ required: true, message: '请输入名称', trigger: 'blur' }],
        legalName: [{ required: true, message: '请输入法人', trigger: 'blur' }],
        contactName: [{ required: true, message: '请输入联系人', trigger: 'blur' }],
        contactMobile: [
          { required: true, message: '请输入联系方式', trigger: 'blur' },
          { pattern: /^1[3456789]\d{9}$/, message: '手机号码格式不正确', trigger: 'blur' }
        ],
        blurb: [
          {
            required: true,
            message: '请填写内容',
            trigger: 'blur'
          }
        ],
        image: [{ required: true, message: '请上传logo' }]
        // file: [{ required: true, message: '请上传其他资料' }]
      }
    };
  },
  created() {},
  methods: {
    async onSubmit() {
      console.log('submit!');
      const dataForm = this.$refs.form;
      await dataForm.validate().then(() => {
        financeBankSave(this.form).then((res) => {
          if (res.code === 200) {
            this.$message({
              message: '您的入驻申请已提交，请耐心等待审核！',
              type: 'success'
            });
          }
        });
      });
    },
    beforeImageUpload(file) {
      return new Promise((resolve, reject) => {
        let isImg = false;
        console.log(file);
        if (this.uploadProfile.fileType.length) {
          let fileExtension = '';
          if (file.name.lastIndexOf('.') > -1) {
            fileExtension = file.name.slice(file.name.lastIndexOf('.') + 1);
          }
          isImg = this.uploadProfile.fileType.some((type) => {
            if (file.type.indexOf(type) > -1) return true;
            if (fileExtension && fileExtension.indexOf(type) > -1) return true;
            return false;
          });
        } else {
          isImg = file.type.indexOf('image') > -1;
        }

        if (!isImg) {
          this.$message.error(`文件格式不正确, 请上传${this.uploadProfile.fileType.join('/')}图片格式文件!`);
          return reject(false);
        }
        if (file.name.lastIndexOf(',') > -1) {
          this.$message.error(`文件名格式不正确, 请确保文件名内不出现’,‘符号!`);
          return reject(false);
        }
        if (this.uploadProfile.fileSize) {
          const isLt = file.size / 1024 / 1024 < this.uploadProfile.fileSize;
          if (!isLt) {
            this.$message.error(`上传图片大小不能超过 ${this.uploadProfile.fileSize} MB!`);
            return reject(false);
          }
        }
        this.loading = this.$loading({
          lock: true,
          text: '上传中',
          background: 'rgba(0, 0, 0, 0.7)'
        });
        return resolve(file);
      });
    },
    handleImageSuccess(res) {
      this.loading.close();
      this.form.image = res.fileName;
    },
    handleFileSuccess(res) {
      this.loading.close();
      this.form.file = res.fileName;
    }
  }
};
</script>

<style lang="less" scoped>
.container {
  background: #f3f9ff;
  .container-box {
    margin: 40px 0;
    padding: 30px;
    border-radius: 10px;
    background: white;
    .icon-img {
      width: 48px;
      margin-left: 20px;
    }
    .form-box {
      padding: 60px 0;
    }
  }
}
</style>
<style>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
.el-form-item__label,
.el-input__inner,
.el-radio__label,
.el-textarea__inner,
.el-form-item__error {
  font-size: 18px;
}
.copyrightRegistration .el-input__inner {
  border: 1px solid #dcdfe6 !important;
  height: 3rem;
}
.copyrightRegistration .el-form-item {
  margin-bottom: 40px;
}

.copyrightRegistration .el-form-item__content {
  margin-left: 150px !important;
}
.copyrightRegistration .el-select {
  width: 100%;
}
.copyrightRegistration .el-radio__input {
  opacity: 0 !important;
}
.copyrightRegistration .el-radio__input {
  opacity: 0 !important;
}
.copyrightRegistration .el-radio {
  padding-left: 0 !important;
  padding-right: 25px !important;
  margin-right: 10px !important;
}

.copyrightRegistration .el-button--primary {
  padding: 20px 80px;
}
.copyrightRegistration .el-button--primary span {
  font-size: 20px;
}
</style>
